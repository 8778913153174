<template>
  <AlertTips
    :tips-content="tipsContent"
    :arrow-position="2"
    @on-click="onTipsClick"
    @on-close="onTipsClose"
  />
</template>

<script setup name="ExpirePointTips">
import { onMounted } from 'vue'
import { setLocalStorage } from '@shein/common-function'
import AlertTips from './AlertTips.vue'
import { triggerLowPriorityTask } from 'public/src/pages/user/child_pages/dashboard/service/utils'

defineProps({
  tipsContent: {
    type: String,
    default: '',
  },
  routerPush: {
    type: Function,
    default: () => {},
  },
})

const emits = defineEmits([
  'hide-module',
  'router-push',
])

onMounted(() => {
  reportModuleExpose()
})

const onTipsClick = () => {
  cacheClickStatus()
  emits('router-push', {
    target: '/user/points',
  })
}

const onTipsClose = () => {
  reportTipsClose()
  cacheClickStatus()
}

const cacheClickStatus = () => {
  const { member_id } = gbCommonInfo.user
  setLocalStorage({
    key: `${member_id}_pointsHit`,
    value: 1,
    expire: 3600 * 24 * 7 * 1000,
  })
  emits('hide-module')
}

const reportModuleExpose = () => triggerLowPriorityTask(() => {
  daEventCenter.triggerNotice({
    daId: '1-25-11-11',
    target: {
      reminder_type: 1,
    },
  })
})

const reportTipsClose = () => daEventCenter.triggerNotice({
  daId: '1-25-11-9',
  target: {
    reminder_type: 1,
    operation: 1
  }
})
</script>
