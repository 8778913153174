import { render, staticRenderFns } from "./ScrollingText.vue?vue&type=template&id=6bf34040&scoped=true&"
import script from "./ScrollingText.vue?vue&type=script&setup=true&lang=js&"
export * from "./ScrollingText.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./ScrollingText.vue?vue&type=style&index=0&id=6bf34040&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.0_css-loader@6.8.1_ejs@2.7.2_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.86.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf34040",
  null
  
)

component.options.__file = "ScrollingText.vue"
export default component.exports