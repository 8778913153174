<template>
  <AlertTips
    :tips-content="tipsContent"
    :arrow-position="1"
    @on-click="onTipsClick"
    @on-close="onTipsClose"
  />
</template>

<script setup name="ExpireCouponTips">
import { onMounted } from 'vue'
import { setLocalStorage } from '@shein/common-function'
import AlertTips from './AlertTips.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { triggerLowPriorityTask } from 'public/src/pages/user/child_pages/dashboard/service/utils'

defineProps({
  tipsContent: {
    type: String,
    default: '',
  },
})

const emits = defineEmits([
  'hide-module',
  'router-push',
])

onMounted(() => {
  reportModuleExpose()
})

const reportModuleExpose = () => triggerLowPriorityTask(() => {
  daEventCenter.triggerNotice({
    daId: '1-25-11-10',
    target: {
      reminder_type: 1,
    },
  })
})

const onTipsClick = () => {
  cacheClickStatus()
  emits('router-push', {
    target: '/user/coupon',
  })
}

const onTipsClose = () => {
  reportTipsClose()
  cacheClickStatus()
}

const cacheClickStatus = () => {
  const { member_id } = gbCommonInfo.user
  setLocalStorage({
    key: `${member_id}_couponHit`,
    value: 1,
    expire: 3600 * 24 * 3 * 1000,
  })
  emits('hide-module')
}

const reportTipsClose = () => daEventCenter.triggerNotice({
  daId: '1-25-11-8',
  target: {
    reminder_type: 1,
    operation: 1,
  }
})
</script>
