<template>
  <div class="rights-fusion-floor__wrapper">
    <div
      v-for="(card, i) in cardList"
      :key="i"
      class="rights-fusion-floor__item"
    >
      <MemberCard
        v-if="card.cardType === UserRightsCardType.MEMBER_CARD"
        class="rights-fusion-floor__prime"
        :style="{
          width: `${fusionItemStyles.containerWidth}rem`,
        }"
        context="fusion-floor"
        :card-info="card"
        :payback-info="rightsInfo?.payback_info"
        :renew-popup-info="rightsInfo?.renew_pop_up_info"
        :renew-popup-info-v2="rightsInfo?.renew_pop_up_info_v2"
        :free-gift-popup-info="rightsInfo?.freeGiftPopupInfo"
      />
      <NewSuperSavePanel
        v-if="card.cardType === UserRightsCardType.SUPER_SAVE"
        v-expose="{
          id: '1-25-11-29',
          data: superSaveReportParams,
        }"
        :card-info="card"
        :style="{
          width: `${fusionItemStyles.containerWidth}rem`,
        }"
        :computed-styles="fusionItemStyles"
        :report-params="superSaveReportParams"
      />
    </div>
  </div>
</template>

<script setup name="UserRightsFusionFloor">
import { computed } from 'vue'
import NewSuperSavePanel from './NewSuperSavePanel.vue'
import MemberCard from 'public/src/pages/user_index/components/UserRightsPanel/MemberCard.vue'
import { getCouponStyles } from 'public/src/pages/user_index/hooks/user-rights-fusion'
import { UserRightsCardType } from 'public/src/pages/user_index/service/consts'

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  primePanelBindData: {
    type: Object,
    default: () => ({}),
  },
})

const cardList = computed(() => props.rightsInfo?.member_card_list || [])
const superSaveCardData = computed(() => cardList.value.find(card => card.cardType === UserRightsCardType.SUPER_SAVE))
const fusionItemStyles = computed(() => {
  const fusionItemCount = cardList.value.length
  const containerWidth = (fusionItemCount > 1 ? 270 : 351) / 37.5
  const couponStyles = getCouponStyles(superSaveCardData.value, fusionItemCount)
  return {
    containerWidth,
    ...couponStyles,
  }
})

const superSaveReportParams = computed(() => {
  const { isSuperSaveMember } = superSaveCardData.value || {}
  const position = cardList.value.findIndex(card => card.cardType === UserRightsCardType.SUPER_SAVE)
  const showMemberCard = cardList.value.some(card => card.cardType === UserRightsCardType.MEMBER_CARD)
  return {
    is_club_show: showMemberCard ? 1 : 0,
    saver_status: isSuperSaveMember ? 'valid' : 'null',
    position: position > -1 ? position + 1 : '',
  }
})
</script>

<style lang="less" scoped>
.rights-fusion-floor {
  &__wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 7/37.5rem 12/37.5rem 0;
    margin-top: -7/37.5rem;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
  &__item:not(:first-child) {
    margin-left: 6/37.5rem;
  }
  &__prime {
    height: 100%;
    /deep/ .atmosphere-bg {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      .paid-vip-content {
        flex: 1;
      }
    }
    /deep/ .paid-vip-ctn {
      height: 80/37.5rem;
      margin: 0;
    }
  }
}
</style>

