<template>
  <div
    v-cloak
    v-expose="{
      id: '1-25-12-25',
      data: reportParams
    }"
    v-tap="{
      id: '1-25-12-26',
      data: reportParams
    }"
    @click="goToPrimeEvt"
  >
    <div
      class="paid-vip-ctn fsp-element"
      :class="{
        'fusion-floor-style': isInFusionFloor,
      }"
    >
      <div
        class="atmosphere-bg"
        :style="{ 
          backgroundImage: 
            `url(${transformImg({ img: GB_cssRight ? props.cardInfo.backgroundImage.rightBgColorImage : props.cardInfo.backgroundImage.leftBgColorImage })}), 
            url(${transformImg({ img: GB_cssRight ? props.cardInfo.backgroundImage.rightBgImage : props.cardInfo.backgroundImage.leftBgImage })})`
        }"
      >
        <div class="paid-vip-header">
          <div class="paid-title">
            <img 
              class="paid-txt"
              :class="isPaidExpired ? '' : 'add-pr'" 
              :src="transformImg({ img: props.cardInfo.cardIcon || '' })"
              decoding="sync"
            />
            <div
              v-if="isPaidExpired && cardInfo?.statusTip && !isInFusionFloor"
              class="paid-txt__expire" 
            >
              {{ props.cardInfo.statusTip }}
            </div>
          </div>
          <div
            v-if="props.cardInfo?.buttonTip"
            :class="showButtonMoreRightIcon ? 'paid-desc' : 'paid-hd__desc'"
            @click.stop="toPay"
          >
            <div 
              v-if="props.cardInfo?.bobbleTip"
              class="renew-discount__tips"
            >
              <div
                class="renew-tips__txt"
                v-html="props.cardInfo.bobbleTip"
              >
              </div>
              <img
                class="renew-tips__img"
                :src="getPaidVipImgsEvt('prime_renew_discount_bg_tips')"
              />
            </div>
            <span
              class="user-rights__prime-btn-text"
            >{{ props.cardInfo.buttonTip }}</span>
            <Icon
              v-if="showButtonMoreRightIcon"
              name="sui_icon_more_right_16px_2"
              size="16px"
            />
          </div>
        </div>
        <div
          class="paid-vip-content"
          :class="{'join-club': loginAndPaidVip}"
        >
          <!-- 引导提示 -->
          <p
            class="prime-tips"
            v-html="props.cardInfo?.richText"
          >
          </p>
          <div
            v-if="showPrimeGoodsImg"
            class="prime-entry-imgs"
          >
            <div
              v-for="(item,idx) in cardRightsGoods"
              :key="idx"
              class="prime-entry-img"
            >
              <img
                :src="transformImg({ img: item.rightImgUrl })"
              />
              <div
                v-if="item.discountTip"
                class="prime-entry-img__discount-info"
              >
                {{ item.discountTip }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="right-icon"
          >
            <img
              v-for="(item, index) in props.cardInfo.rightInfo"
              :key="index"
              :src="transformImg({ img: item.right_type_icon })"
              alt=""
              decoding="sync"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 续费弹窗 -->
    <div @click.stop="">
      <PersonRenewDialogNewStyle
        v-if="renewPopupInfoV2"
        :renew-popup-info="renewPopupInfoV2"
        :is-show="isShowRenewDialogNew"
      />
      <PersonRenewDialog
        v-else
        :renew-pop-up-info="renewPopupInfo"
        :is-show="isShowRenewDialog && renewPopupInfo"
      />
    </div>
    <div @click.stop="">
      <!-- 免费礼物弹窗 优先级续>免费礼物>回本-->
      <FreeGiftDialog
        v-if="!isShowRenewDialog && !isShowRenewDialogNew && showFreeGiftPopup"
        :free-gift-popup-info="freeGiftPopupInfo"
      />
    </div>
    <!-- 回本动效弹窗 todo-->
    <div @click.stop="">
      <PayBackDialog
        :payback-info="paybackInfo"
        :is-show="!isShowRenewDialog && !isShowRenewDialogNew && !showFreeGiftPopup"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, inject } from 'vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon } from '@shein-aidc/icon-vue2'
import { canShowRenewDialog, canShowRenewDialogNew } from './dialogHooks'

const { pushLoginRoute } = inject('USER_CENTER_CONTEXT') || {}

/* component */
import PersonRenewDialog from './PersonRenewDialog.vue'
import PayBackDialog from './PayBackDialog.vue'
import FreeGiftDialog from './free-gift-dialog/FreeGiftDialog.vue'
import { transformImg } from '@shein/common-function'

const PersonRenewDialogNewStyle = () => import(/* webpackChunkName: person-renew-dialog-new-style" */ './PersonRenewDialogNewStyle/index')

const { GB_cssRight, PUBLIC_CDN } = gbCommonInfo


const props = defineProps({
  freeGiftPopupInfo: { type: Object, default: () => ({}) },
  cardInfo: { type: Object, default: () => ({}) },
  paybackInfo: { type: Object, default: null },
  renewPopupInfo: { type: Object, default: null },
  renewPopupInfoV2: { type: Object, default: null },
  context: { type: String, default: 'independent' },
})

/* computed */
const isInFusionFloor = computed(() => props.context === 'fusion-floor')
const isPaidExpired = computed(() => Number(props.cardInfo?.status) === 2)
const showButtonMoreRightIcon = computed(() => props.cardInfo?.buttonStyle === 2) // 1为普通按钮，2为带箭头样式
const cardRightsGoods = computed(() => props.cardInfo?.rightGoods || [])
const showPrimeGoodsImg = computed(() => isInFusionFloor.value && !!cardRightsGoods.value.length)
const isShowRenewDialog = computed(() => canShowRenewDialog(props.renewPopupInfo || {}))
const isShowRenewDialogNew = computed(() => canShowRenewDialogNew(props.renewPopupInfoV2 || {}))
const showFreeGiftPopup = computed(() => props.freeGiftPopupInfo?.giftInfoList?.length)

const reportParams = computed(() => {
  const { buriedObject } = props.cardInfo
  const item = buriedObject?.find(item => item.buriedKey === 'prime_entry')
  if (!item) return
  return item.defaultParams
})

// 已登录且是付费会员
const loginAndPaidVip = computed(() => props.cardInfo?.isPaid === 1 && isLogin())

/* methods */
const goToPrimeEvt = () => {
  pushLoginRoute?.({
    target: '/user/prime?productsource=me',
    bi: 'club',
  })
}

const getPaidVipImgsEvt = (type) =>{
  const imgsMap = {
    paid_vip_logo: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo-088fc507b3.svg',
    // paid_vip_logo_ar: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo_ar-a578769e95.svg',
    paid_vip_logo_text: PUBLIC_CDN + '/pwa_dist/images/pay_vip/paid_vip_logo_text-21a27b7d3f.png',
    paid_vip_split: PUBLIC_CDN + '/pwa_dist/images/pay_vip/pay_vip_split-eea3fc8ee3.png', // PNG 更小
    prime_renew_discount_bg: PUBLIC_CDN + '/pwa_dist/images/user/prime/sui_img_limitedoffertag_atmosphere-f9524b35fe.svg',
    prime_renew_discount_bg_tips: PUBLIC_CDN + '/pwa_dist/images/pay_vip/shein-club-bottom-arrow-1aa4262348.png',
  }
  return imgsMap[type]
}

const toPay = () => {
  const { url, appRoute } = props.cardInfo
  pushLoginRoute?.({
    target: appRoute || url,
    bi: 'club',
  })
}

</script>
<style lang="less">
.paid-vip-ctn {
  margin: 0 .32rem;
  height: 1.7067rem;
  border-radius: .16rem;
  border: 1px solid rgba(255, 208, 184, 0.50);
  background: linear-gradient(270deg, rgba(255, 186, 151, 0.40) 0%, rgba(255, 186, 151, 0.29) 100%), #FFF;
  .atmosphere-bg {
    width: 100%;
    height: 100%;
    // background-repeat: no-repeat;
    background-size: cover, cover;
   
  
    .paid-vip-header {
      background-position: 0 0;
      background-size: cover;
      display: flex;
      align-items: center;
      min-height: 53/75rem;
      z-index: 2;/* stylelint-disable-line declaration-property-value-blacklist */
      position: relative;
      .padding-l(234/75rem);
      .padding-r(16/75rem);
      position: relative;

      .paid-title{
        display: flex;
        align-items: center;
        height: .6933rem;
        padding: 0px 0 0px .2133rem;
        border-radius: .16rem 0px .32rem 0px;
        background: linear-gradient(180deg, rgba(255, 204, 178, 0.25) 0%, rgba(255, 175, 134, 0.25) 100%);
        flex-shrink: 0;
        position: absolute;
        left: 0;

        .paid-txt{
          background-position: 0 0;
          background-size: contain;
          background-repeat: no-repeat;
          width: 180/75rem;
          height: 38/75rem;
        }

        .add-pr {
          margin-right: .2133rem;
        }

        .paid-txt__expire {
          background: #E5E5E5;
          border: 1px solid rgba(187, 187, 187, 0.5);
          border-radius: .56rem;
          padding: .0267rem 6px;
          color: #767676;
          font-size: .2667rem;
          transform: scale(.8);
        }
      }

      .paid-hd__desc {
        position: absolute;
        padding: .0533rem .2133rem;
        border-radius: .6933rem;
        border: .0267rem solid #873C00;
        color: #873C00;
        text-align: center;
        font-size: .32rem;
        font-style: normal;
        font-weight: 600;
        font-family: "SF UI Display";
        line-height: normal;
        .right(0);
        margin: .1333rem .2133rem 0 0;
      }

      .paid-desc{
        margin: 0;
        position: absolute;
        right: 0;
        color: #873C00;
        line-height: 28/75rem;
        padding: 10/75rem 0;
        display: flex;
        align-items: center;
        .margin-l(18/75rem);
        .margin-r(18/75rem);
        .txt-r();

        i {
          .fr();
        }

        span{
          .txt-r();
        }
        .renew-discount__tips {
          top: -33%;
          .renew-tips__txt {
            padding-bottom: 1px;
            padding-top: 1px;
          }
        }
      }
      
      .renew-discount__tips {
        position: absolute;
        top: -77%;
        max-width: 140/37.5rem;
        .right(0);
        .renew-tips__txt {
          white-space: nowrap;
          border-radius: .0533rem;
          background: linear-gradient(90deg, #C96E3F 48.82%, #EAA27E 100%);   
          color: #FFFFFF;
          padding: .0533rem .1067rem;
          text-align: center;
          font-family: 'SF UI Display';
          font-size: 9px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .renew-tips__img {
          width: .16rem !important; 
          height: .10667rem !important; 
          position: absolute;
          bottom: -.10667rem;
          .left(80%);
        }
      }
    }

    .paid-vip-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-basis: 1.8133rem;
      margin: 0 .2667rem;
      height: .96rem;
      color: #873C00;

      .prime-tips {
        margin-right: .32rem;
        padding: .16rem 0;
        max-width: 6.7467rem;
        max-height: .96rem;
        font-size: .32rem;
        font-weight: 400;
        font-style: normal;
        line-height: .3733rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        span {
          font-size: 12px;
          font-weight: 400;
          color: #FA6338;
        }

        span > span{
          font-weight: 700;
        }
      }
      
      .right-icon {
        display: flex;

        img {
          width: .5333rem;
          height: .5333rem;
            &:not(:last-child) {
            margin-right: .1067rem;
          }
        }
      }
    }

    .prime-entry-imgs {
      display: flex;
    }
    .prime-entry-img {
      width: 42/37.5rem;
      height: 42/37.5rem;
      border: 1/37.5rem solid #fff;
      border-radius: 2/37.5rem;
      margin-right: 2/37.5rem;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 2/37.5rem;
      }
      &__discount-info {
        position: absolute;
        width: 100%;
        height: 13/37.5rem;
        left: 0;
        bottom: 0;
        border-bottom-left-radius: 2/37.5rem;
        border-bottom-right-radius: 2/37.5rem;
        background-color: #873C00;
        color: #fff;
        font-size: 9/37.5rem;
        font-family: SF-UI-Text-Regular;
        line-height: 13/37.5rem;
        text-align: center;
        .line-camp(1);
        word-break: break-all;
      }
    }
  }
  &.fusion-floor-style {
    border-radius: 2/37.5rem;
    border: 0.5px solid rgba(255, 208, 184, 0.70);
    background: linear-gradient(274deg, rgba(255, 211, 189, 0.40) 0.6%, rgba(255, 186, 151, 0.29) 96.44%), #FFF;
    .user-rights__prime-btn-text {
      display: block;
      max-width: 104/37.5rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      font-family: 'SF-UI-Text-Bold';
      font-weight: 700;
      line-height: 14/37.5rem;
    }
    .atmosphere-bg {
      background: none;
      background-image: none !important;
    }
    .paid-title {
      background: none !important;
    }
  }
}
</style>
