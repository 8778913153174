<template>
  <div class="new-user-benefits__coupon-list">
    <CouponItem
      v-for="(coupon, index) in coupons"
      :key="index"
      v-expose="{
        id: '1-25-11-26',
        data: {
          module_nm: 'new_user_coupon',
          coupon_code: coupon.couponData?.couponCode || '',
          item_loc: index + 1,
          content_list: jumpUrl,
        },
      }"
      :index="index"
      :coupon-data="coupon.couponData"
      :coupon-style="coupon.couponStyle"
      :href-info="hrefInfo"
    />
  </div>
</template>

<script setup name="NewUserCouponList">
import { computed } from 'vue'
import CouponItem from './CouponItem.vue'
import { getCccJumpUrl } from 'public/src/pages/user_index/service/utils'
import { setCouponsStyles } from 'public/src/pages/user_index/hooks/new-user-benefits'

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  hrefInfo: {
    type: Object,
    default: () => ({}),
  },
})

const coupons = computed(() => setCouponsStyles(props.rightsInfo?.couponList || []))
const jumpUrl = computed(() => getCccJumpUrl(props.hrefInfo) || '')
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__coupon-list {
    white-space: nowrap;
  }
}
</style>
