<template>
  <div
    class="new-user-benefits__coupon-item"
    :style="itemStyles"
    @click.stop="onCouponItemClick"
  >
    <div
      class="new-user-benefits__coupon-left"
      :style="itemLeftStyles"
    >
      <template v-if="couponRules.length">
        <div
          v-for="(rule, i) in couponRules"
          :key="i"
          class="new-user-benefits__coupon-rule"
          :style="{
            maxWidth: `${90 / couponRules.length}%`
          }"
        >
          <s-adapter-text
            class="new-user-benefits__coupon-discount"
            :class="[couponStyle.discountFontSizeClass]"
            :style="{
              lineHeight: `${couponStyle.discountLineHeightRem}rem`,
            }"
            :min-size="12"
            :text="rule.discountAmountText"
          />
          <s-adapter-text
            v-if="rule.useConditionText"
            class="new-user-benefits__coupon-rule-tips"
            :style="{
              lineHeight: `${couponStyle.ruleTipsLineHeightRem}rem`,
            }"
            :min-size="9"
            :text="rule.useConditionText"
          />
        </div>
      </template>
    </div>
    <div class="new-user-benefits__coupon-right">
      <div
        class="new-user-benefits__coupon-use-btn"
      >
        <s-adapter-text
          class="new-user-benefits__coupon-use-text"
          :min-size="9"
          :text="couponData.useText"
        />
      </div>
    </div>
  </div>
</template>

<script setup name="NewUserCouponItem">
import { ref, computed } from 'vue'
import { AdapterText as SAdapterText } from '@shein/sui-mobile'
import { getCccJumpUrl } from 'public/src/pages/user_index/service/utils'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  couponData: {
    type: Object,
    default: () => ({}),
  },
  couponStyle: {
    type: Object,
    default: () => ({}),
  },
  hrefInfo: {
    type: Object,
    default: () => ({}),
  },
})

const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)
const couponRules = computed(() => props.couponData?.rule || [])
const itemStyles = computed(() => {
  const { couponWidthRem } = props.couponStyle || {}
  const circlePosX = GB_cssRight.value
    ? 62 / 37.5 - couponWidthRem / 2
    : couponWidthRem / 2 - 62 / 37.5
  return {
    width: `${couponWidthRem}rem`,
    webkitMask: `radial-gradient(circle, transparent 6px, black 6px) ${circlePosX}rem ${-22 / 37.5}rem`,
  }
})
const itemLeftStyles = computed(() => {
  const { couponWidthRem } = props.couponStyle || {}
  return {
    maxWidth: `${couponWidthRem - 62.5 / 37.5}rem`,
  }
})

const onCouponItemClick = () => {
  const { index, couponData } = props
  const jumpUrl = getCccJumpUrl(props.hrefInfo) || ''
  setTimeout(() => {
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-27',
    bindData: {
      module_nm: 'new_user_coupon',
      coupon_code: couponData?.couponCode || '',
      item_loc: index + 1,
      content_list: jumpUrl,
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__coupon-item {
    display: inline-flex;
    align-items: stretch;
    height: 44/37.5rem;
    border-radius: 2/37.5rem;
    border: 0.5px solid rgba(255, 84, 10, 0.30);
    background: linear-gradient(103deg, #FFF 10.22%, #FFFEE3 72.13%, #FFF 95.37%);
    &:first-child {
      margin-left: 8/37.5rem;
    }
    &:not(:first-child) {
      margin-left: 5/37.5rem;
    }
  }
  &__coupon-left {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;
  }
  &__coupon-rule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__coupon-discount {
    font-family: 'SF-UI-Text-Bold';
    font-weight: 800;
    color: #5E2B0E;
    text-align: center;
  }
  &__coupon-discount-fz12 {
    font-size: 12px;
  }
  &__coupon-discount-fz14 {
    font-size: 14px;
  }
  &__coupon-rule-tips {
    font-family: 'SF-UI-Text-Regular';
    font-weight: 400;
    font-size: 10px;
    color: #5E2B0E;
    text-align: center;
  }
  &__coupon-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 62.5/37.5rem;
    border-left: 0.5px dashed rgba(249, 96, 53, 0.25);
  }
  &__coupon-use-btn {
    display: flex;
    justify-content: center;
    width: 54/37.5rem;
    height: 22/37.5rem;
    border-radius: 11/37.5rem;
    background-color: #FF540A;
    padding: 5/37.5rem 4/37.5rem 0;
  }
  &__coupon-use-text {
    color: #fff;
    font-family: 'SF-UI-Text-Regular';
    font-size: 11px;
    line-height: 13/37.5rem;
    text-align: center;
  }
}
</style>
