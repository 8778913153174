<template>
  <CommonWrapper
    v-expose="{
      id: '1-25-11-26',
      data: {
        module_nm: 'new_user_promo',
        opt_nm: ugActivityType || 0,
        content_list: rightsInfo?.jumpUrl || '',
      },
    }"
    :rights-info="rightsInfo"
  >
    <div
      class="new-user-benefits__banner"
      @click.stop="onBannerClick"
    ></div>
  </CommonWrapper>
</template>

<script setup name="SingleBanner">
import CommonWrapper from './CommonWrapper.vue'
import { daEventCenter } from 'public/src/services/eventCenter'
import { getCccJumpUrl } from 'public/src/pages/user_index/service/utils'

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  ugActivityType: {
    type: Number,
    default: 0,
  },
  hrefInfo: {
    type: Object,
    default: () => ({}),
  },
})

const onBannerClick = () => {
  const jumpUrl = getCccJumpUrl(props.hrefInfo) || ''
  setTimeout(() => {
    if (!jumpUrl) return
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-27',
    bindData: {
      module_nm: 'new_user_promo',
      opt_nm: props.ugActivityType || 0,
      content_list: jumpUrl,
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__banner {
    height: 111/37.5rem;
  }
}
</style>
