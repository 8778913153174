<template>
  <CommonWrapper
    :rights-info="rightsInfo"
  >
    <div
      class="new-user-benefits__coupons"
      @click.stop="onModuleClick"
    >
      <CouponList
        :rights-info="rightsInfo"
        :href-info="hrefInfo"
      />
    </div>
  </CommonWrapper>
</template>

<script setup name="NewUserCoupons">
import CommonWrapper from './CommonWrapper.vue'
import CouponList from './new-user-coupon/CouponList.vue'
import { getCccJumpUrl } from 'public/src/pages/user_index/service/utils'
import { daEventCenter } from 'public/src/services/eventCenter'

const props = defineProps({
  rightsInfo: {
    type: Object,
    default: () => ({}),
  },
  hrefInfo: {
    type: Object,
    default: () => ({}),
  },
})

const onModuleClick = () => {
  const jumpUrl = getCccJumpUrl(props.hrefInfo) || ''
  setTimeout(() => {
    location.href = jumpUrl
  }, 300)
  daEventCenter.triggerNotice({
    daId: '1-25-11-27',
    bindData: {
      module_nm: 'new_user_coupon',
      coupon_code: '',
      item_loc: '',
      content_list: jumpUrl,
    },
  })
}
</script>

<style lang="less" scoped>
.new-user-benefits {
  &__coupons {
    height: 85/37.5rem;
    padding-top: 33/37.5rem;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 0;
    }
  }
}
</style>
