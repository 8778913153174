/**
 * 趋势频道内容体
 */
import { getLocalStorage, setLocalStorage } from '@shein/common-function'

const STAMP_ONE_HOUR = 1000 * 60 * 60

const STAMP_ONE_DAY = STAMP_ONE_HOUR * 24

const MAX_FATIGUE = 3

let pageName = 'trend_landing'

export const setPageName = val => pageName = val

export const getPageName = () => pageName

// 进入记录,回退时删除
export const topTrendPageViewStack = []

// 记录趋势频道入口访问
export const recordTopTrendPageView = () => {
  setPageName('trend_landing')
  // 最近一次访问记录，自然日后过期
  setLocalStorage({
    key: getLocalStorageKey('top_trend_page_view'),
    value: 1,
    expire: getZeroStamp(),
  })
  topTrendPageViewStack.push(1)
}

// 删除趋势频道入口访问
export const removeTopTrendPageView = () => {
  topTrendPageViewStack.pop()
}
// 获取localStorage key
export const getLocalStorageKey = (prefix) => {
  return `${getPageName()}_${prefix}`
}

// 获取当前时间距离X天后0点的时间戳
export const getZeroStamp = (x = 1) => {
  const curTimestamp = Date.now() // 当前时间戳
  const curDayZeroClock = new Date()
  curDayZeroClock.setHours(0, 0, 0, 0)
  const curDayZeroTimestamp = curDayZeroClock.getTime() // 当天0时时间戳
  return STAMP_ONE_DAY - (curTimestamp - curDayZeroTimestamp) + STAMP_ONE_DAY * Number(x - 1)
}

const visitedTopTrendPage = () => {
  // 暂时只针对趋势词落地页处理
  if (pageName !== 'trend_landing') return false
  return !!getLocalStorage(getLocalStorageKey('top_trend_page_view'))
}

// 是否满足展示趋势频道入口的条件
export const showTopTrendCard = (ignoreVisit) => {
  const isClick = getTrendCardIsClick()
  if (isClick) return false
  // 是否自然日内访问过趋势频道
  if (visitedTopTrendPage()) return false
  // 是否达到疲劳值 访问趋势词页出现内容体(曝光) 疲劳值+1
  const fatigues = getTrendCardFatigue()
  if (fatigues.length >= MAX_FATIGUE) return false
  if (ignoreVisit) return true
  // 是否访问过趋势频道
  const isVisited = topTrendPageViewStack.length > 0
  if (isVisited) return false
  return !isVisited
}

// 记录内容体点击
export const recordClickTrendCard = () => {
  const key = getLocalStorageKey('click_trend_card')
  setLocalStorage({
    key,
    value: 1,
    expire: getZeroStamp(),
  })
}

// 获取用户是否点击过趋势词入口
const getTrendCardIsClick = () => {
  const key = getLocalStorageKey('click_trend_card')
  return !!getLocalStorage(key)
}

// 记录疲劳值
export const recordTrendCardFatigue = (trendId) => {
  const key = getLocalStorageKey('fatigue')
  const trendIds = getTrendCardFatigue()
  if (trendIds.length >= MAX_FATIGUE) return
  trendIds.push(trendId)
  setLocalStorage({
    key,
    value: trendIds,
    expire: getZeroStamp(),
  })
}

// 获取疲劳值
export const getTrendCardFatigue = () => {
  const key = getLocalStorageKey('fatigue')
  return getLocalStorage(key) || []
}

